import React from 'react'
import { Card, Header, Icon, Image } from 'semantic-ui-react';
import example from "../../assets/habitTrackerPageExample.png";

const HabitPage: React.FC = () => {
    return (
        <>
            <Card centered style={{marginTop: '25px', width: '80%'}}>
                <Card.Content textAlign='center' header='Habit Tracker Project' />
                <Card.Description>
                This personal project is a habit tracker application designed to help users build or break habits effectively. The application provides a user-friendly system for tracking progress and staying motivated.
                <p/>
                It features a user account creation and login system to ensure personalized tracking. Once logged in, users can define their habits, monitor their streaks, and set goals for the duration they wish to maintain a habit. The tracker also includes a flexible "cheat day" feature, allowing users to allocate a set number of cheat days without breaking their streaks. 
                <p/>
                <p/>
                This project was built with an emphasis on simplicity and usability, aiming to make habit tracking intuitive and accessible. It’s a tool to encourage consistency and accountability while accommodating the occasional slip-up, helping users stay on track toward their personal goals.
                </Card.Description>
                <Card.Content>• Backend designed in C# .NET Core 5.0</Card.Content>
                <Card.Content>• Frontend designed in React using TypeScript and the Semantic UI framework</Card.Content>
                <Card.Content>
                    <Icon name='github' size='large'/>
                    <a
                        className="App-link"
                        href="https://github.com/dapowers87/habit-tracker"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        GitHub Repository
                    </a>
                </Card.Content>
                <Card.Content>
                    <Icon name='linkify' size='large'/>
                    <a
                        className="App-link"
                        href="https://habit-tracker.davidpowers.dev"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Project Website
                    </a>   
                </Card.Content>
                <Card.Content textAlign='center'>
                    <Header as='h3'>Example:</Header>
                    <Image bordered fluid src={example} size="huge"/>
                </Card.Content>
            </Card>
        </>
    )
}

export default HabitPage;